import React from 'react';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Header1 from '../components/header1';

const useStyles = makeStyles(theme => ({}));

const PrivacyPolicy = () => {
  const classes = useStyles();

  return (
    <>
      <Container>
        <Header1 title="VOW PRIVACY POLICY" />
        <p> Last updated 10.2.2020</p>
      </Container>
      <Container>
        <Grid container>
          <Grid item sm={6} style={{ marginBottom: 60 }}>
            <h2 style={{ margin: 0 }}>INTRODUCTION</h2>
            <p>
              Vow ASA, being an international corporation, consists of several
              subsidiary companies throughout the world. Vow ASA acknowledges
              privacy as a fundamental human right and seek to minimize the
              collection and use of personal data and provide transparency and
              control over such information. We are committed to the privacy of
              our users, customers, suppliers and employees. The following
              privacy policy describes how we collect, use, disclose and
              otherwise process personally identifiable information about
              individuals, either as data controllers or as data handlers.
            </p>
          </Grid>
          <Grid item sm={6} />
          <Grid item sm={6} style={{ marginBottom: 60 }}>
            <h2 style={{ margin: 0 }}>INFORMATION WE COLLECT</h2>
            <p>
              If you use our public web sites, we collect and process basic
              usage data. This can include your IP address, browser and
              operating system information, which pages you visit, source of
              referral, and date and time of use. This information is logged by
              the web server software and may be used for analyzing traffic
              patterns and system performance to maintain and improve our web
              service. You can read more about this in our cookie policy. If you
              contact us by email or other electronic means, we may collect and
              process the correspondence itself including standard email
              headers. We use this data for the purpose of communicating with
              you. Related service data like server logs which can contain
              timestamps, originating mail server IPs and other technical
              metadata are also collected, and used to monitor and maintain
              system performance. If you willfully provide us with information
              regarding our goods or services, this information may be collected
              and processed for the purpose of handling your enquiries, but not
              for other purposes.
            </p>
            <p>
              This information is used for communicating with you regarding
              contractual matters, for invoicing and other financial operations,
              and may be used to contact you regarding relevant company
              activities. Financial information including transactions are kept
              in accordance with national laws in the respective countries we
              operate.
            </p>
            <p>
              We may be provided personal information from other sources, where
              our role is to be data handlers. In these cases, we only collect
              and use the information as needed to perform our role in the
              specific project.
            </p>
          </Grid>
          <Grid item sm={6} />
          <Grid item sm={6} style={{ marginBottom: 60 }}>
            <h2 style={{ margin: 0 }}>DISSEMINATION OF INFORMATION</h2>
            <p>
              Information collected at Vow ASA, may be disclosed to other Vow
              subsidiaries, but only if it is required for the purposes
              described in this policy.
            </p>
            <p>
              Vow has offices or facilities in Norway, USA, Poland and France.
              The hosting is provided by Amazon Web Services (AWS), information
              about the data centers compliance can be found at
              <br />
              <a
                href="https://aws.amazon.com/compliance/data-privacy/"
                target="_blanc"
              >
                https://aws.amazon.com/compliance/data-privacy/
              </a>
            </p>
            <p>
              Data transfer between Vow subsidiary companies’ facilities and to
              and from hosting facilities will be protected by appropriate
              safeguards.
            </p>
          </Grid>
          <Grid item sm={6} />
          <Grid item sm={6} style={{ marginBottom: 60 }}>
            <h2 style={{ margin: 0 }}>RETENTION OF INFORMATION</h2>
            <p>
              Personal data will be retained only for the duration that is
              necessary for the purposes described in this policy.
            </p>
            <p>
              Data may be retained shorter or longer only to the extent required
              to comply with laws like national accounting acts.
            </p>
            <p>
              Technical log data which may contain personal information will be
              retained for a minimum of 1 month and a maximum of 6 months.
            </p>
          </Grid>
          <Grid item sm={6} />
          <Grid item sm={6} style={{ marginBottom: 60 }}>
            <h2 style={{ margin: 0 }}>AMENDMENTS</h2>
            <p>
              This policy may be updated from time to time. Any new version will
              be published on our website https://vowasa.com/.
            </p>
            <p>
              If major changes are done to the policy, we may contact you
              directly by email to notify you of this.
            </p>
          </Grid>
          <Grid item sm={6} />
          <Grid item sm={6} style={{ marginBottom: 60 }}>
            <h2 style={{ margin: 0 }}>YOUR RIGHTS</h2>
            <p>
              Your privacy rights are protected under the EU General Data
              Protection Regulation. These include, but are not limited to, the
              right to access, correct, delete and extract your personal
              information.
            </p>
            <p>
              You have the right to get confirmation as to if, where and how we
              keep or process your personal data, including information about
              the purpose and categories of data.
            </p>
            <p>
              You have the right to get incorrect data corrected or incomplete
              data completed.
            </p>
            <p>
              You have the right to erasure of your personal data in certain
              circumstances. These include: objections under data protection
              law; the data is no longer required for the purpose intended; or
              the data has been misused outside its intended purpose. Erasure
              requests may be declined if the data is required for compliance
              with legal obligations or in relation to legal claims.
            </p>
            <p>
              You have the right to receive your personal data from us in a
              structured, machine readable format, unless it would adversely
              affect the rights of others.
            </p>
            <p>
              If you wish to contact us to exercise your rights related to your
              personal data, please send a written notice by email to the
              addresses listed below.
            </p>
          </Grid>
          <Grid item sm={6} />
          <Grid item sm={6} style={{ marginBottom: 60 }}>
            <h2 style={{ margin: 0 }}>DETAILS</h2>
            <p>
              Our main website is https://vowasa.com/, owned by Vow ASA,
              operated by Silverbullet Film AB and hosted by Amazon Web Services
              (AWS).
            </p>
            <p>
              VOW ASA is registered in Norway, registration number NO 996 819
              000 MVA. Our registered office address is Lysaker Torg 12, 1366
              Lysaker NORWAY.
            </p>
            <p>You can contact us by email to info@vowasa.com.</p>
          </Grid>
          <Grid item sm={6} />
          <Grid item sm={6} style={{ marginBottom: 60 }}>
            <h2 style={{ margin: 0 }}>DATA PROTECTION OFFICER</h2>
            <p>
              Our data protection officer can be contacted by email on
              info@vowasa.com.
            </p>
          </Grid>
          <Grid item sm={6} />
        </Grid>
      </Container>
    </>
  );
};

export default PrivacyPolicy;
